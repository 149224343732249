import { getCookie } from "../../storage";
import { getMilisecondsDifference } from "../../utils";
import { addToast, cleanToastsContainer } from "../../components/toasts";

$(() => {
  // Toasts error
  const minutes = parseInt($("#loginSettings").attr("data-minutes"));
  const minutesRestore = parseInt(
    $("#loginSettings").attr("data-minutes-restore")
  );
  const tries = parseInt($("#loginSettings").attr("data-tries"));
  const maxTries = parseInt($("#loginSettings").attr("data-max-tries"));
  const messageChangePasswordError = $("#loginSettings").attr(
    "data-change-password-error"
  );
  const messageChangePasswordErrorSingular = $("#loginSettings").attr(
    "data-change-password-error-singular"
  );
  const messageBlockedPasswordError = $("#loginSettings").attr(
    "data-blocked-password-error"
  );
  const messageBlockedPasswordErrorSingular = $("#loginSettings").attr(
    "data-blocked-password-error-singular"
  );
  const messageBlockedRestorePasswordError = $("#loginSettings").attr(
    "data-blocked-restore-password-error"
  );
  const messageBlockedRestorePasswordErrorSingular = $("#loginSettings").attr(
    "data-blocked-restore-password-error-singular"
  );
  const cookiePasswordBlockTime = getCookie("ett-password-block-time");
  const cookieRestorePasswordBlockTime = getCookie(
    "ett-password-restore-block-time"
  );

  if ($("#showWarningPasswordBlock").length > 0) {
    const message =
      tries == maxTries - 1
        ? messageChangePasswordErrorSingular
        : messageChangePasswordError;
    addToast(message, "warning", 1000000);
  }

  if (
    $("#showErrorPasswordBlock").length > 0 ||
    $("#showErrorRestorePasswordBlock").length > 0
  ) {
    let message = "";
    let miliseconds = 1000000;
    if (minutes > minutesRestore) {
      message =
        minutes == 1
          ? messageBlockedPasswordErrorSingular
          : messageBlockedPasswordError;
      miliseconds = getMilisecondsDifference(
        new Date(),
        new Date(cookiePasswordBlockTime)
      );
    } else {
      message =
        minutesRestore == 1
          ? messageBlockedRestorePasswordErrorSingular
          : messageBlockedRestorePasswordError;
      miliseconds = getMilisecondsDifference(
        new Date(),
        new Date(cookieRestorePasswordBlockTime)
      );
    }
    addToast(message, "error", 1000000);

    // Timeout unlock login button
    setTimeout(function () {
      $("#submit-login").removeAttr("disabled");
      $("#blocked-access-error").remove();
      cleanToastsContainer();
    }, miliseconds);
  }
});
