﻿export function getCookie(name) {
  const decodedCookies = decodeURIComponent(document.cookie);
  const cookies = decodedCookies.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    const cookieName = name + "=";

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }

  return "";
}

export function setCookie(name, value, path, expire) {
  try {
    let cookieValue = `${name}=${encodeURIComponent(value)};`;

    if (expire && expire instanceof Date) {
      const _expire = `expires=${expire.toUTCString()};`;
      cookieValue = `${cookieValue}${_expire}`;
    }

    if (path) {
      const _path = `path=${path};`;
      cookieValue = `${cookieValue}${_path}`;
    }

    document.cookie = cookieValue;
  } catch (error) {
    throw new Error("setCookie ERROR", error);
  }
}

export function deleteCookie(name, path) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
}
